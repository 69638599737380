@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Ubuntu:wght@400;500;700&display=swap");
html {
  scroll-behavior: smooth;
}

:root {
  /* color */
  --main-color: rgb(0, 156, 246);
  --secondery-color: rgb(98, 98, 98);
  /* font */
  --ubuntu: "Ubuntu", sans-serif;
  --poppins: "Poppins", sans-serif;
  --montserrat: "Montserrat", sans-serif;
}
* {
  font-family: var(--montserrat);
}
body::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}
body::-webkit-scrollbar-track {
  background: rgb(0, 0, 0); /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: var(--main-color); /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  border: 1px solid transparent; /* creates padding around scroll thumb */
}

.bg-navbar {
  background-color: rgb(0, 81, 173);
}
.brand-align {
  display: block;
}
.logo-name {
  color: var(--main-color);
  font-family: var(--ubuntu);
  font-size: 30px;
  font-weight: bold;
}
.home-container {
  position: relative;
  height: 70vh;
  width: 100%;
  background-image: url("./components/images/dev1.jpg");
  background-size: cover;

  object-fit: cover;
}

.opacity-bg {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.774);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.title {
  width: calc(100vw / 2);
  font-size: 40px;
  text-align: center;
  color: white;
  font-weight: bold;
  margin: 25px;
  /* background-color: rgba(0, 0, 0, 0.89); */
}
@media (max-width: 1000px) {
  .title {
    width: calc(100vw / 1.3);
  }
}
.button {
  outline: none;
  border: none;
  padding: 10px;
  width: 200px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: var(--main-color);
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s;
}
.button:hover {
  background-color: rgb(5, 117, 221);
}

@media (max-width: 650px) {
  .home-container {
    background-position: center;
    height: 40vh;
  }
  .opacity-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .title {
    font-size: 20px;
    margin: 25px;
  }
  .button {
    padding: 10px;
    width: 150px;
    height: 50px;
    transform: scale(0.9);
  }
}

/* About us */

.about-container {
  background-color: var(--main-color);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-content-div {
  padding: 10px;
  /* width: calc((99vw / 1.5)); */
  width: 90%;
  /* background-color: white; */
  border-radius: 15px;
  margin: 20px 0;
}

@media (max-width: 800px) {
  .about-content-div {
    width: calc((100vw / 1.1));
  }
}
@media (max-width: 400px) {
  .about-content-div {
    width: calc((100vw));
  }
}
.about-content-div p {
  margin: 10px 10px;
  /* color: var(--secondery-color); */
  color: white;
  font-size: 20px;
  text-align: justify;
}

/* Section Title */
.section-div {
  display: inline;
  justify-content: center;
  margin: 40px 0;
}

.section-title {
  /* top: 0;
  left: 50%; */
  /* transform: translateX(-50%); */
  color: white;
  font-size: 35px;
  font-family: var(--ubuntu);
  font-weight: lighter;
  position: relative;
  display: inline;
  line-height: 0;
}
.section-title.blue {
  color: var(--main-color);
}
.section-title::after {
  content: "";
  position: absolute;
  text-align: center;
  width: 80%;
  left: 50%;
  height: 3px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  border-radius: 50px;
}
.section-title.blue::after {
  background-color: var(--main-color);
}

/* carousel */

.carousel-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.all-carousel {
  width: 80%;
  display: inline-flex;
  justify-content: center;
  height: auto;
}
.div-per-carousel {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 80vw;
}
.carousel-div p {
  text-align: center;
  font-size: 25px;
  color: white;
}
.carousel-image-div {
  margin: 10px;
  height: 600px;
  width: 800px;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  padding: 10px;
}

.carousel-image {
  width: 100%;
  height: 80%;
  background-color: burlywood;
}
.carousel-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-text {
  font-size: 25px;
  background-color: rgb(240, 240, 240);
  margin: 20px;
  color: var(--secondery-color);
}

/* contact us */

.contact-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cantact-all-info {
  margin: 40px;
  width: 50%;
  display: flex;
  justify-content: space-around;
}
.cantact-us-text {
  width: 30%;
  text-align: center;
  font-size: 20px;
  color: var(--secondery-color);
}

.info-content {
  text-align: center;
}
.info-icon {
  color: var(--main-color);
  font-size: 50px;
}
.info-text {
  color: var(--secondery-color);
  font-size: 20px;
}
.info-text.email {
  color: var(--main-color);
  cursor: pointer;
}
@media (max-width: 650px) {
  .cantact-all-info {
    flex-direction: column;
  }
  .cantact-us-text {
    width: 90%;
  }
}

/* footer */
.footer-div {
  width: 100%;
  padding: 20px;
  text-align: center;
  background-color: rgb(224, 224, 224);
}
@media (max-width: 800px) {
  .footer-text {
    flex-direction: column;
  }
}
.footer-text {
  color: var(--secondery-color);
  display: flex;
  justify-content: space-around;

  align-items: center;
}
.footer-text p {
  position: relative;
  top: 10px;
}
.footer-text .links {
  margin: 15px 0;
}
.footer-text .links a {
  margin: 0 15px;
}

.social-media {
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-media .social-icon {
  margin: 0 30px;
  cursor: pointer;
}
.social-media .social-icon:nth-child(1) {
  color: rgb(0, 60, 156);
}
.social-media .social-icon:nth-child(2) {
  color: rgb(4, 157, 228);
}
.social-media .social-icon:nth-child(3) {
  color: rgb(0, 77, 107);
}
.modal-div {
  height: 80vh;
  width: 100%;
}

.nav-shadow {
  box-shadow: 5px 10px #888888;
  background-color: red;
  padding: 10px;
}

.red {
  background-color: red;
}
