@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Ubuntu:wght@400;500;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Montserrat",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.nitifi-sty {
  position: sticky;
  top: 0;

  height: 40px;
  background: white;
  display: flex;

  justify-content: center;
  align-items: center;
  z-index: 20;
}
.static-nav {
  font-size: 15px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.contact-link {
  margin: 3px 10px;
}
.contact-link img {
  margin: 3px 10px;
}
.navbar-sty {
  position: sticky;
  top: 40px;
  z-index: 20;
}
@media (max-width: 750px) {
  .nitifi-sty {
    height: 50px;
  }
  .navbar-sty {
    top: 50px;
  }
  .static-nav {
    flex-direction: column;
  }
}

@media (max-width: 404px) {
  .contact-link {
    margin: 3px 5px;
  }
  .contact-link img {
    margin: 3px 5px;
  }
  .nitifi-sty {
    height: 70px;
  }
  .navbar-sty {
    top: 70px;
  }
}
