/* at your service */
.at-your-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 10%;
}
.service-all-card {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 95%;
}
.service-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 15px 10px;

  width: calc(100vw / 4);
}
.icon-bg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  background-color: rgb(251, 251, 251);
  border-radius: 50%;
  transition: 0.3s;
}

.icon-bg:before {
  display: flex;
  content: "";
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 3px solid var(--main-color);
  transition: 0.3s ease-in-out;
}
.icon {
  font-size: 30px;
  transition: 0.2s ease-in;
}

.icon-bg:hover {
  background-color: var(--main-color);
}
.icon-bg:hover .icon {
  color: white;
  font-size: 40px;
  font-weight: bold;
}

.icon-bg:hover::before {
  animation: wave 0.5s ease-in-out;
}
@keyframes wave {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.6);
    border: 3px solid #1dced100;
  }
}

.ceyan {
  color: var(--main-color);
}

.service-title {
  border-radius: 5px;
  padding: 0 10px;
  position: relative;
  margin-top: 20px;
  font-size: 25px;
  color: var(--main-color);
  margin-bottom: 10px;
  font-weight: 800;
}

.service-subtitle {
  font-size: 20px;
  color: var(--secondery-color);
  width: 95%;
}

@media (max-width: 900px) {
  .service-card {
    width: 95%;
  }
  .service-all-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
