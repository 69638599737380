/* our works */
.our-works {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--main-color);
  padding: 20px;
}
/* .all-works {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
} */

.work-div {
  margin: 10px;
  padding: 10px;
  width: 65%;
  height: auto;
  background-color: rgb(246, 246, 246);
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
}

.work-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 5px;
  height: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  object-fit: cover;
  overflow: hidden;
}
.work-image img {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 30px #0000001c;
  z-index: 1;
  object-fit: cover;
}
/* .work-image img:nth-child(1) {
  left: 50px;
  top: 40%;
} */
.work-content {
  flex-direction: column;
  padding: 0 20px;
  width: 40%;
  overflow: auto;
  scroll-behavior: smooth;
}

.main-content,
.sub-content {
  margin: 15px 0;
}
.company-name {
  font-size: 35px;
  font-weight: bold;
  color: rgb(47, 47, 47);
}
.company-info {
  color: var(--secondery-color);
  font-size: 18px;
  text-align: justify;
}
.used-technology {
  color: var(--secondery-color);
  font-size: 18px;
  margin: 5px 0;
}
.lable {
  font-weight: bold;
  color: rgb(47, 47, 47);
  font-size: 18px;
}
.category {
  color: var(--secondery-color);
  font-size: 18px;
  margin: 5px 0;
}
.btn-div {
  margin: 20px;
  width: 100%;
  text-align: center;
}
.visit-button {
  width: 30%;
  background-color: var(--main-color);
}
.slid-div {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1400px) {
  .work-div {
    width: 90%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .main-content,
  .sub-content {
    margin: 15px 10px;
  }
  .work-image {
    flex-direction: column;
    width: 100%;
    height: 100%;

    border-radius: 0px;
  }
  .work-image img {
    flex-direction: column;
    width: 100%;
    border-radius: 0px;
  }
  .work-content {
    width: 100%;
    overflow: auto;
  }
}
.work-content::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}
.work-content::-webkit-scrollbar-track {
  background: rgb(236, 236, 236); /* color of the tracking area */
}

.work-content::-webkit-scrollbar-thumb {
  background-color: rgb(200, 200, 200); /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  border: 3px solid transparent; /* creates padding around scroll thumb */
}
