@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Ubuntu:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Ubuntu:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Montserrat",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.nitifi-sty {
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  height: 40px;
  background: white;
  display: flex;

  justify-content: center;
  align-items: center;
  z-index: 20;
}
.static-nav {
  font-size: 15px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.contact-link {
  margin: 3px 10px;
}
.contact-link img {
  margin: 3px 10px;
}
.navbar-sty {
  position: -webkit-sticky;
  position: sticky;
  top: 40px;
  z-index: 20;
}
@media (max-width: 750px) {
  .nitifi-sty {
    height: 50px;
  }
  .navbar-sty {
    top: 50px;
  }
  .static-nav {
    flex-direction: column;
  }
}

@media (max-width: 404px) {
  .contact-link {
    margin: 3px 5px;
  }
  .contact-link img {
    margin: 3px 5px;
  }
  .nitifi-sty {
    height: 70px;
  }
  .navbar-sty {
    top: 70px;
  }
}

html {
  scroll-behavior: smooth;
}

:root {
  /* color */
  --main-color: rgb(0, 156, 246);
  --secondery-color: rgb(98, 98, 98);
  /* font */
  --ubuntu: "Ubuntu", sans-serif;
  --poppins: "Poppins", sans-serif;
  --montserrat: "Montserrat", sans-serif;
}
* {
  font-family: "Montserrat", sans-serif;
  font-family: var(--montserrat);
}
body::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}
body::-webkit-scrollbar-track {
  background: rgb(0, 0, 0); /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(0, 156, 246);
  background-color: var(--main-color); /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  border: 1px solid transparent; /* creates padding around scroll thumb */
}

.bg-navbar {
  background-color: rgb(0, 81, 173);
}
.brand-align {
  display: block;
}
.logo-name {
  color: rgb(0, 156, 246);
  color: var(--main-color);
  font-family: "Ubuntu", sans-serif;
  font-family: var(--ubuntu);
  font-size: 30px;
  font-weight: bold;
}
.home-container {
  position: relative;
  height: 70vh;
  width: 100%;
  background-image: url(/static/media/dev1.9add4eb9.jpg);
  background-size: cover;

  object-fit: cover;
}

.opacity-bg {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.774);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.title {
  width: calc(100vw / 2);
  font-size: 40px;
  text-align: center;
  color: white;
  font-weight: bold;
  margin: 25px;
  /* background-color: rgba(0, 0, 0, 0.89); */
}
@media (max-width: 1000px) {
  .title {
    width: calc(100vw / 1.3);
  }
}
.button {
  outline: none;
  border: none;
  padding: 10px;
  width: 200px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: rgb(0, 156, 246);
  background-color: var(--main-color);
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s;
}
.button:hover {
  background-color: rgb(5, 117, 221);
}

@media (max-width: 650px) {
  .home-container {
    background-position: center;
    height: 40vh;
  }
  .opacity-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .title {
    font-size: 20px;
    margin: 25px;
  }
  .button {
    padding: 10px;
    width: 150px;
    height: 50px;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}

/* About us */

.about-container {
  background-color: rgb(0, 156, 246);
  background-color: var(--main-color);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-content-div {
  padding: 10px;
  /* width: calc((99vw / 1.5)); */
  width: 90%;
  /* background-color: white; */
  border-radius: 15px;
  margin: 20px 0;
}

@media (max-width: 800px) {
  .about-content-div {
    width: calc((100vw / 1.1));
  }
}
@media (max-width: 400px) {
  .about-content-div {
    width: calc((100vw));
  }
}
.about-content-div p {
  margin: 10px 10px;
  /* color: var(--secondery-color); */
  color: white;
  font-size: 20px;
  text-align: justify;
}

/* Section Title */
.section-div {
  display: inline;
  justify-content: center;
  margin: 40px 0;
}

.section-title {
  /* top: 0;
  left: 50%; */
  /* transform: translateX(-50%); */
  color: white;
  font-size: 35px;
  font-family: "Ubuntu", sans-serif;
  font-family: var(--ubuntu);
  font-weight: lighter;
  position: relative;
  display: inline;
  line-height: 0;
}
.section-title.blue {
  color: rgb(0, 156, 246);
  color: var(--main-color);
}
.section-title::after {
  content: "";
  position: absolute;
  text-align: center;
  width: 80%;
  left: 50%;
  height: 3px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  border-radius: 50px;
}
.section-title.blue::after {
  background-color: rgb(0, 156, 246);
  background-color: var(--main-color);
}

/* carousel */

.carousel-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.all-carousel {
  width: 80%;
  display: inline-flex;
  justify-content: center;
  height: auto;
}
.div-per-carousel {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 80vw;
}
.carousel-div p {
  text-align: center;
  font-size: 25px;
  color: white;
}
.carousel-image-div {
  margin: 10px;
  height: 600px;
  width: 800px;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  padding: 10px;
}

.carousel-image {
  width: 100%;
  height: 80%;
  background-color: burlywood;
}
.carousel-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-text {
  font-size: 25px;
  background-color: rgb(240, 240, 240);
  margin: 20px;
  color: rgb(98, 98, 98);
  color: var(--secondery-color);
}

/* contact us */

.contact-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cantact-all-info {
  margin: 40px;
  width: 50%;
  display: flex;
  justify-content: space-around;
}
.cantact-us-text {
  width: 30%;
  text-align: center;
  font-size: 20px;
  color: rgb(98, 98, 98);
  color: var(--secondery-color);
}

.info-content {
  text-align: center;
}
.info-icon {
  color: rgb(0, 156, 246);
  color: var(--main-color);
  font-size: 50px;
}
.info-text {
  color: rgb(98, 98, 98);
  color: var(--secondery-color);
  font-size: 20px;
}
.info-text.email {
  color: rgb(0, 156, 246);
  color: var(--main-color);
  cursor: pointer;
}
@media (max-width: 650px) {
  .cantact-all-info {
    flex-direction: column;
  }
  .cantact-us-text {
    width: 90%;
  }
}

/* footer */
.footer-div {
  width: 100%;
  padding: 20px;
  text-align: center;
  background-color: rgb(224, 224, 224);
}
@media (max-width: 800px) {
  .footer-text {
    flex-direction: column;
  }
}
.footer-text {
  color: rgb(98, 98, 98);
  color: var(--secondery-color);
  display: flex;
  justify-content: space-around;

  align-items: center;
}
.footer-text p {
  position: relative;
  top: 10px;
}
.footer-text .links {
  margin: 15px 0;
}
.footer-text .links a {
  margin: 0 15px;
}

.social-media {
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-media .social-icon {
  margin: 0 30px;
  cursor: pointer;
}
.social-media .social-icon:nth-child(1) {
  color: rgb(0, 60, 156);
}
.social-media .social-icon:nth-child(2) {
  color: rgb(4, 157, 228);
}
.social-media .social-icon:nth-child(3) {
  color: rgb(0, 77, 107);
}
.modal-div {
  height: 80vh;
  width: 100%;
}

.nav-shadow {
  box-shadow: 5px 10px #888888;
  background-color: red;
  padding: 10px;
}

.red {
  background-color: red;
}

.contect-us-map {
  width: 100%;
  height: auto;
  background: linear-gradient(#f9f7f7, #bebebe);
}
.map-div {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.map {
  width: 50%;
  height: auto;
}
.address {
  height: 50px;
  width: 65%;
  position: relative;
}
.hr {
  position: absolute;
  background-color: white;
  height: 5px;
  width: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.281);
}
.dots {
  position: absolute;
  width: 100%;
  height: 80%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dot {
  height: 25px;
  width: 25px;
  /* background: linear-gradient(#ff636e, #fd1951); */
  border-radius: 50%;
}
.dot img {
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 40px;
  border-radius: 2px;
}
.dot:nth-child(1) {
  /* background: linear-gradient(#8cc6f0, #8c91f0); */
  padding-top: 2px;
}
.dot:nth-child(2) {
  /* background: linear-gradient(#e246f0, #8c46f0); */
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}
.dot:nth-child(3) {
  /* background: linear-gradient(#8cc6f0, #8c91f0); */
  padding-top: 4px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}
.location {
  /* position: absolute; */
  top: 100%;
  width: 70%;

  height: auto;
  display: flex;
  justify-content: space-between;
}
.location .content {
  width: calc(100% / 1);
  height: auto;
  color: white;
  padding: 10px;
  border-radius: 10px;
  text-align: left;
}
.location .content p {
  margin: 2px;
}
.icons {
  /* font-size: 20px;
  margin: 0 5px; */
}
.location .content:nth-child(1) {
  background: linear-gradient(#ff636e, #fd1951);
  height: 10%;
}
.location .content:nth-child(2) {
  background: linear-gradient(#e246f0, #8c46f0);
  height: 10%;
}
.location .content:nth-child(3) {
  background: linear-gradient(#8cc6f0, #8c91f0);
  height: 10%;
}
.location .content:nth-child(1) {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: linear-gradient(#ff636e, #fd1951);
}

.location .content:nth-child(3) {
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
  background: linear-gradient(#8cc6f0, #8c91f0);
} /* 
.social-media {
  margin: 20px;
  font-size: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.social-media .social-icon {
  margin: 0 10px;
  cursor: pointer;
}
.social-media .social-icon:nth-child(1) {
  color: rgb(0, 60, 156);
}
.social-media .social-icon:nth-child(2) {
  color: rgb(4, 157, 228);
}
.social-media .social-icon:nth-child(3) {
  color: rgb(0, 77, 107);
} */

@media (max-width: 1200px) {
  .location .content {
    width: calc(100% / 3.3);
    /* margin-bottom: 20px; */
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
  }
  .map {
    width: 80%;
  }
  .location {
    width: 60%;
    height: auto;
  }
  .address {
    position: relative;
    bottom: 0%;
    width: 60%;
  }
}

@media (max-width: 900px) {
  .map {
    width: 100%;
  }
  .location {
    width: 80%;
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }
  .location .content {
    width: 80%;

    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
  }
  .location .content:nth-child(1) {
    background: linear-gradient(#ff636e, #fd1951);

    -webkit-transform: translateX(0);

            transform: translateX(0);
  }
  .location .content:nth-child(2) {
    background: linear-gradient(#e246f0, #8c46f0);

    -webkit-transform: translateX(0);

            transform: translateX(0);
  }
  .location .content:nth-child(3) {
    background: linear-gradient(#8cc6f0, #8c91f0);

    -webkit-transform: translateX(0);

            transform: translateX(0);
  }

  .address {
    display: none;
  }
}

/* at your service */
.at-your-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 10%;
}
.service-all-card {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 95%;
}
.service-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 15px 10px;

  width: calc(100vw / 4);
}
.icon-bg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  background-color: rgb(251, 251, 251);
  border-radius: 50%;
  transition: 0.3s;
}

.icon-bg:before {
  display: flex;
  content: "";
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 3px solid var(--main-color);
  transition: 0.3s ease-in-out;
}
.icon {
  font-size: 30px;
  transition: 0.2s ease-in;
}

.icon-bg:hover {
  background-color: var(--main-color);
}
.icon-bg:hover .icon {
  color: white;
  font-size: 40px;
  font-weight: bold;
}

.icon-bg:hover::before {
  -webkit-animation: wave 0.5s ease-in-out;
          animation: wave 0.5s ease-in-out;
}
@-webkit-keyframes wave {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    border: 3px solid #1dced100;
  }
}
@keyframes wave {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    border: 3px solid #1dced100;
  }
}

.ceyan {
  color: var(--main-color);
}

.service-title {
  border-radius: 5px;
  padding: 0 10px;
  position: relative;
  margin-top: 20px;
  font-size: 25px;
  color: var(--main-color);
  margin-bottom: 10px;
  font-weight: 800;
}

.service-subtitle {
  font-size: 20px;
  color: var(--secondery-color);
  width: 95%;
}

@media (max-width: 900px) {
  .service-card {
    width: 95%;
  }
  .service-all-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

/* our works */
.our-works {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--main-color);
  padding: 20px;
}
/* .all-works {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
} */

.work-div {
  margin: 10px;
  padding: 10px;
  width: 65%;
  height: auto;
  background-color: rgb(246, 246, 246);
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
}

.work-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 5px;
  height: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  object-fit: cover;
  overflow: hidden;
}
.work-image img {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 30px #0000001c;
  z-index: 1;
  object-fit: cover;
}
/* .work-image img:nth-child(1) {
  left: 50px;
  top: 40%;
} */
.work-content {
  flex-direction: column;
  padding: 0 20px;
  width: 40%;
  overflow: auto;
  scroll-behavior: smooth;
}

.main-content,
.sub-content {
  margin: 15px 0;
}
.company-name {
  font-size: 35px;
  font-weight: bold;
  color: rgb(47, 47, 47);
}
.company-info {
  color: var(--secondery-color);
  font-size: 18px;
  text-align: justify;
}
.used-technology {
  color: var(--secondery-color);
  font-size: 18px;
  margin: 5px 0;
}
.lable {
  font-weight: bold;
  color: rgb(47, 47, 47);
  font-size: 18px;
}
.category {
  color: var(--secondery-color);
  font-size: 18px;
  margin: 5px 0;
}
.btn-div {
  margin: 20px;
  width: 100%;
  text-align: center;
}
.visit-button {
  width: 30%;
  background-color: var(--main-color);
}
.slid-div {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1400px) {
  .work-div {
    width: 90%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .main-content,
  .sub-content {
    margin: 15px 10px;
  }
  .work-image {
    flex-direction: column;
    width: 100%;
    height: 100%;

    border-radius: 0px;
  }
  .work-image img {
    flex-direction: column;
    width: 100%;
    border-radius: 0px;
  }
  .work-content {
    width: 100%;
    overflow: auto;
  }
}
.work-content::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}
.work-content::-webkit-scrollbar-track {
  background: rgb(236, 236, 236); /* color of the tracking area */
}

.work-content::-webkit-scrollbar-thumb {
  background-color: rgb(200, 200, 200); /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  border: 3px solid transparent; /* creates padding around scroll thumb */
}

/* we-work-with */
.we-work-with {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.all-tech {
  padding: 20px;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1000px) {
  .all-tech {
    width: 70%;
  }
}
@media (max-width: 750px) {
  .all-tech {
    width: 90%;
  }
}
@media (max-width: 450px) {
  .all-tech {
    width: 100%;
  }
}
.Technologies {
  margin: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  width: 140px;
  /* background-color: antiquewhite; */
  border-radius: 10px;
  overflow: hidden;
}
.tech-img {
  height: 70%;
  width: 100%;
  object-fit: contain;
}
.tech-name {
  color: var(--secondery-color);
  font-size: 20px;
}

